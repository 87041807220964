// export const FIREBASE_CONFIG = {
//   apiKey: "AIzaSyD4PW-EkfMYF5t-Uq7vft3jreIGksPzCOs",
//   authDomain: "plugins-playground-7971a.firebaseapp.com",
//   projectId: "plugins-playground-7971a",
//   storageBucket: "plugins-playground-7971a.appspot.com",
//   messagingSenderId: "66818322724",
//   appId: "1:66818322724:web:c03ec9054d26ffb40e83b1",
//   measurementId: "G-H0VZ9TTFMY"
// };

export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyBAJrDDPLFarlTBgsuXe97miLfqNClmqWI",
  authDomain: "fynegames-apps.firebaseapp.com",
  databaseURL: "https://fynegames-apps-default-rtdb.firebaseio.com",
  projectId: "fynegames-apps",
  storageBucket: "fynegames-apps.appspot.com",
  messagingSenderId: "268124718389",
  appId: "1:268124718389:web:2590492c8e30c2a380f87c",
  measurementId: "G-7HE0Q75ZDV"
};