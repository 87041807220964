import {JsonObject} from "../common/json/json-object";
import {JsonProperty} from "../common/json/json-property";
import {v4 as uuid} from "uuid";
import {ReactElement} from "react";
import {PathProps} from "../index";
import {BaseListItem, BaseListItemsLoader} from "../shared/types";
import {JSON_OBJECT} from "../shared/json/helpers";
import {getMemberAuth} from "../shared/auth";
import {Member, Members, User} from "../shared/entities";
import {DateUtil, WEEK_IN_MILLIS} from "../shared/date_util";

export const ROOM_CODE_LENGTH = 6;
export const MAX_LETTERS = 12;

@JsonObject()
export class DrawSegment {

  @JsonProperty()
  readonly color: string;

  @JsonProperty()
  readonly lineWidth: number;

  @JsonProperty()
  readonly points: number[];

  constructor(color: string, lineWidth: number, points: number[]) {
    this.color = color;
    this.lineWidth = lineWidth;
    this.points = points;
  }
}

@JsonObject()
export class Room extends BaseListItem {

  joiner: Member;

  @JsonProperty()
  joinedBy: string;

  @JsonProperty()
  joinedAt: number;

  @JsonProperty()
  difficulty: string;

  @JsonProperty()
  word: string;

  @JsonProperty()
  letters: string[];

  @JsonProperty()
  drawerId: string;

  @JsonProperty()
  completedAt: number;

  @JsonProperty()
  abandonedAt: number;

  constructor(id: string, creator: string, created: number) {
    super(id, creator, created);
  }

  isDrawer(): boolean {
    return this.drawerId === getMemberAuth().getMemberId();
  }

  updateSelf(isDrawer: boolean, difficulty: string, word: string, letters: string[]): Room {
    this.drawerId = isDrawer ? getMemberAuth().getMemberId() : this.joinedBy;
    this.difficulty = difficulty;
    this.word = word;
    this.letters = letters;
    this.completedAt = undefined;
    this.abandonedAt = undefined;
    return this;
  }

  async onAfterItemDeserialized(): Promise<void> {
    if (this.joinedBy) {
      this.joiner = await Members.getInstance().getOrLoadMember(this.joinedBy);
    }
  }

  isCompleted(): boolean {
    return this.completedAt > 0;
  }

  isPlayable(): boolean {
    return Date.now() < this.created + WEEK_IN_MILLIS && Boolean(this.joinedAt) && Boolean(this.joinedBy) && !this.completedAt && !this.abandonedAt;
  }
}

export class Rooms extends BaseListItemsLoader<Room> {

  private static instance: Rooms;

  static getInstance(): Rooms {
    if (!this.instance) {
      this.instance = new Rooms();
    }
    return this.instance;
  }

  constructor() {
    super({shared: true});
  }

  protected basePath(): string {
    return "rooms";
  }

  async loadListItems(): Promise<void> {
    throw new Error("Not supported. We should never try to list all rooms.")
  }

  protected deserializeItem(value: any): Room {
    return JSON_OBJECT.deserializeObject(value, Room);
  }

  protected serializeItem(item: Room): any {
    return JSON_OBJECT.serializeObject(item);
  }

  protected sortOrder(item1: Room, item2: Room): number {
    return 0;
  }
}

@JsonObject()
export class RoomJoin extends BaseListItem {

  static createNew(roomId: string): RoomJoin {
    return new RoomJoin(roomId, getMemberAuth().getMemberId(), Date.now());
  }

  constructor(id: string, creator: string, created: number) {
    super(id, creator, created);
  }
}

export class RoomJoins extends BaseListItemsLoader<RoomJoin> {

  private static instance: RoomJoins;

  static getInstance(): RoomJoins {
    if (!this.instance) {
      this.instance = new RoomJoins();
    }
    return this.instance;
  }

  protected basePath(): string {
    return "room_joins";
  }

  protected deserializeItem(value: any): RoomJoin {
    return JSON_OBJECT.deserializeObject(value, RoomJoin);
  }

  protected serializeItem(item: RoomJoin): any {
    return JSON_OBJECT.serializeObject(item);
  }

  protected sortOrder(item1: RoomJoin, item2: RoomJoin): number {
    return item2.created - item1.created;
  }
}

@JsonObject()
export class RoomDrawing extends BaseListItem {

  @JsonProperty()
  readonly segments: DrawSegment[] = [];

  static createNew(roomId: string, segments: DrawSegment[]): RoomDrawing {
    return new RoomDrawing(roomId, getMemberAuth().getMemberId(), Date.now(), segments);
  }

  constructor(id: string, creator: string, created: number, segments: DrawSegment[]) {
    super(id, creator, created);
    this.segments = segments;
  }
}

export class RoomDrawings extends BaseListItemsLoader<RoomDrawing> {

  private static instance: RoomDrawings;

  static getInstance(): RoomDrawings {
    if (!this.instance) {
      this.instance = new RoomDrawings();
    }
    return this.instance;
  }

  constructor() {
    super({shared: true});
  }

  protected basePath(): string {
    return "room_drawings";
  }

  protected deserializeItem(value: any): RoomDrawing {
    return JSON_OBJECT.deserializeObject(value, RoomDrawing);
  }

  protected serializeItem(item: RoomDrawing): any {
    return JSON_OBJECT.serializeObject(item);
  }

  protected sortOrder(item1: RoomDrawing, item2: RoomDrawing): number {
    return item2.created - item1.created;
  }
}

@JsonObject()
export class RoomWord extends BaseListItem {

  @JsonProperty()
  readonly word: string;

  static createNew(roomId: string, word: string): RoomWord {
    return new RoomWord(roomId, getMemberAuth().getMemberId(), Date.now(), word);
  }

  constructor(id: string, creator: string, created: number, word: string) {
    super(id, creator, created);
    this.word = word;
  }
}

export class RoomWords extends BaseListItemsLoader<RoomWord> {

  private static instance: RoomWords;

  static getInstance(): RoomWords {
    if (!this.instance) {
      this.instance = new RoomWords();
    }
    return this.instance;
  }

  constructor() {
    super({shared: true});
  }

  protected basePath(): string {
    return "room_words";
  }

  protected deserializeItem(value: any): RoomWord {
    return JSON_OBJECT.deserializeObject(value, RoomWord);
  }

  protected serializeItem(item: RoomWord): any {
    return JSON_OBJECT.serializeObject(item);
  }

  protected sortOrder(item1: RoomWord, item2: RoomWord): number {
    return item2.created - item1.created;
  }
}

@JsonObject()
export class Score extends BaseListItem {

  static createNew(gameId: string, level: string, points: number, duration: number) {
    const memberId = getMemberAuth().getMemberId();
    const now = new Date();
    return new Score(uuid(), memberId, now.getTime(), gameId, level, DateUtil.toDatestamp(now), points, duration);
  }

  @JsonObject()
  readonly scoreId;
  @JsonProperty()
  readonly gameId: string;
  @JsonProperty()
  readonly level: string;
  @JsonProperty()
  readonly ds: string;
  @JsonProperty()
  readonly points: number;
  @JsonProperty()
  readonly duration: number;

  constructor(scoreId: string, creator: string, created: number, gameId: string, level: string, ds: string, points: number, duration: number) {
    super(scoreId, creator, created);
    this.scoreId = scoreId;
    this.gameId = gameId;
    this.level = level;
    this.ds = ds;
    this.points = points;
    this.duration = duration;
  }
}

export function ScoreToString(score: Score) {
  return ScoreTimeToString(score.created);
}

export function ScoreTimeToString(time: number) {
  return new Date(time).toLocaleString("en-us", {
    dateStyle: "medium",
    timeStyle: "short"
  })
}

export class Scores extends BaseListItemsLoader<Score> {

  private static instance;

  static getInstance(): Scores {
    if (!this.instance) {
      this.instance = new Scores();
    }
    return this.instance;
  }

  protected basePath(): string {
    return "scores";
  }

  protected deserializeItem(value: any): Score {
    return JSON_OBJECT.deserializeObject(value, Score);
  }

  protected serializeItem(item: Score): any {
    return JSON_OBJECT.serializeObject(item);
  }

  protected sortOrder(item1: Score, item2: Score): number {
    // Reverse chronological
    return item2.created - item1.created;
  }
}

export type ScoreItem = {
  user: User,
  score: Score,
}

export class ScoreItems {

  readonly list: ScoreItem[] = [];

  add(user: User, score: Score) {
    this.list.push({user: user, score: score});
  }
}

export type GameLevel = {
  name: string,
  text: string,
}

export type Game = {
  id: string,
  name: string,
  description: string,
  icon: string,
  color: string,
  levels: GameLevel[],
  render: (pathProps: PathProps, ...args) => ReactElement,
}
