export type PasskeyConfig = {
  type: "numeric" | "alphabetic_upper" | "alphabetic_lower" | "alphabetic" | "alphanumeric_upper" | "alphanumeric_lower" | "alphanumeric",
  length: number,
}

const NUMBERS = "0123456789";
const ALPHABETS = "abcdefghijklmnopqrstuvwxyz";

export function passkey(config: PasskeyConfig): string {
  let passkey = "";
  const length = Math.min(Math.max(Math.floor(config.length), 1), 12);
  let n = 0;
  let string;
  switch (config.type) {
    case "numeric":
      string = NUMBERS;
      break;
    case "alphabetic_upper":
      string = ALPHABETS.toUpperCase();
      break;
    case "alphabetic_lower":
      string = ALPHABETS;
      break;
    case "alphabetic":
      string = ALPHABETS.toUpperCase() + ALPHABETS;
      break;
    case "alphanumeric_upper":
      string = NUMBERS + ALPHABETS.toUpperCase();
      break;
    case "alphanumeric_lower":
      string = NUMBERS + ALPHABETS;
      break;
    case "alphanumeric":
      string = NUMBERS + ALPHABETS.toUpperCase() + ALPHABETS;
      break;
  }
  while (n++ < length) {
    passkey += String.fromCharCode(string.charCodeAt(Math.floor(Math.random() * string.length)));
  }
  return passkey;
}
