import React from "react";
import {FooterTabFragment} from "../../shared/FooterTabFragment";
import {StyledBoxColumn, StyledBoxColumnWithBackgroundCoverImage} from "../../shared/StyledComponents";
import {colorEarthGreen, colorEarthRed, colorEarthYellow} from "../../shared/colors";
import {Action} from "../../shared/types";
import {NewGameHelper, renderGameButton} from "./EditGameHelper";


export class HomeFragment extends FooterTabFragment {

  renderContent(): React.ReactElement {
    return <StyledBoxColumnWithBackgroundCoverImage
      backgroundCoverImage={"/images/cover.png"}
      style={{flexGrow: 1, alignItems: "center", justifyContent: "center", position: "relative"}}>
      <StyledBoxColumn>
        {renderGameButton(new Action("Create game", () => new NewGameHelper(this.props.path).createNewGame()), colorEarthRed)}
        {renderGameButton(new Action("Current games", () => this.props.path.navigate("/games")), colorEarthYellow)}
        {renderGameButton(new Action("Find friends", () => this.props.path.navigate("/people")), colorEarthGreen)}
      </StyledBoxColumn>
    </StyledBoxColumnWithBackgroundCoverImage>;
  }
}