import {AppConfig, BaseApp, BaseAppProps, BaseAppState, clearProvisioningId} from "shared/BaseApp";
import React, {createRef, ReactElement, RefObject} from 'react';
import {createTheme, ThemeOptions} from "@mui/material";
import {colorAlabaster, DEFAULT_THEME_OPTIONS} from "../shared/colors";
import {Main} from "./Main";
import {PathComponent} from "../index";
import {FIREBASE_CONFIG} from "../consts";
import {BORDER_RADIUS, SZ_SM} from "../shared/dimens";
import {Auth, indexedDBLocalPersistence, initializeAuth} from "@firebase/auth";
import {FirebaseApp} from "firebase/app";
import {Capacitor} from "@capacitor/core";

export type AppProps = BaseAppProps & {}

type AppState = BaseAppState & {}

export const COLOR_PRIMARY = "#d64e51";

export const THEME_OPTIONS: ThemeOptions = {
  ...DEFAULT_THEME_OPTIONS,
  components: {
    ...DEFAULT_THEME_OPTIONS.components,
    MuiTab: {
      styleOverrides: {
        root: {
          fontWeight: "bold",
          fontSize: "75%",
          borderRadius: BORDER_RADIUS,
        },
      }
    },
  },
  typography: {
    h1: {
      fontWeight: "bold",
      lineHeight: 1,
    },
    h2: {
      fontWeight: "bold",
      lineHeight: 1,
    },
    h3: {
      fontWeight: "bold",
      lineHeight: 1,
    },
    h4: {
      fontWeight: "bold",
      lineHeight: 1,
    },
    h5: {
      fontWeight: "bold",
      lineHeight: 1,
    },
    h6: {
      fontWeight: "bold",
      lineHeight: 1,
    },
    // fontFamily: 'THICCCBOI, sans-serif',
    fontSize: 13,
  },
  palette: {
    primary: {
      main: COLOR_PRIMARY,
      contrastText: "#fff",
    },
    secondary: {
      main: "#000",
      contrastText: "#fff",
    },
    background: {
      default: colorAlabaster,
    },
  }
};

export const THEME = createTheme(THEME_OPTIONS);

export class AppRefs {

  constructor(
    readonly localAudioRenderer: RefObject<HTMLAudioElement>,
    readonly localAudioElement: ReactElement,
    readonly localVideoRenderer: RefObject<HTMLVideoElement>,
    readonly localVideoElement: ReactElement,
    readonly remoteAudioRenderer: RefObject<HTMLAudioElement>,
    readonly remoteAudioElement: ReactElement,
    readonly remoteVideoRenderer: RefObject<HTMLVideoElement>,
    readonly remoteVideoElement: ReactElement) {
  }
}

export class App extends BaseApp<AppProps, AppState> {

  private static appRefs: AppRefs;

  static getAppRefs(): AppRefs {
    if (!App.appRefs) {
      App.appRefs = App.createAppRefs();
    }
    return App.appRefs;
  }

  private static createAudioElement(ref: RefObject<HTMLAudioElement>, muted: boolean): ReactElement {
    return <audio ref={ref} muted={muted}/>;
  }

  private static createVideoElement(ref: RefObject<HTMLVideoElement>): ReactElement {
    return <video ref={ref} autoPlay playsInline muted
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    borderRadius: BORDER_RADIUS,
                  }}></video>;
  }

  private static createAppRefs() {
    const localAudioRenderer = createRef<HTMLAudioElement>();
    const localVideoRenderer = createRef<HTMLVideoElement>();
    const remoteAudioRenderer = createRef<HTMLAudioElement>();
    const remoteVideoRenderer = createRef<HTMLVideoElement>();
    return new AppRefs(
      localAudioRenderer,
      App.createAudioElement(localAudioRenderer, true),
      localVideoRenderer,
      App.createVideoElement(localVideoRenderer),
      remoteAudioRenderer,
      App.createAudioElement(remoteAudioRenderer, false),
      remoteVideoRenderer,
      App.createVideoElement(remoteVideoRenderer));
  }

  constructor(props: AppProps, context: any) {
    super(props, context);
    clearProvisioningId();
  }

  protected getAuth(app: FirebaseApp): Auth {
    return Capacitor.isNativePlatform()
      ? initializeAuth(app, {
        persistence: indexedDBLocalPersistence
      })
      : super.getAuth(app);
  }

  onCreateAppConfig(): AppConfig {
    return {
      name: "Pikaso",
      icon: "/icon.png",
      logo: "/logo192.png",
      stamp: "/stamp.png",
      stampHeight: SZ_SM,
      stampText: "Pikaso",
      safeAreaInsets: {
        bottom: Capacitor.getPlatform() === "ios" ? 12 : 0,
      },
      defaultUserImage: "/images/default_user.png",
      theme: THEME,
      firebaseConfig: {options: FIREBASE_CONFIG},
    };
  }

  static nestedPaths(): PathComponent[] {
    return Main.nestedPaths();
  }

  protected renderMain(): ReactElement {
    return <>
      <Main path={this.props.path}/>
    </>;
  }
}
