import React from "react";
import {Box, Button, Typography} from "@mui/material";
import {PD_LG, PD_SM, PD_XSM} from "shared/dimens";
import {MailOutlineOutlined, PersonOutlined} from "@mui/icons-material";
import {ConnectionsFragment} from "./ConnectionsFragment";
import {InvitationsFragment} from "./InvitationsFragment";
import {FooterTabFragment, FooterTabFragmentProps, FooterTabFragmentState} from "../../shared/FooterTabFragment";
import {StyledBoxColumnWithBackgroundCoverImage} from "../../shared/StyledComponents";

export type PeopleFragmentProps = FooterTabFragmentProps & {}

type PeopleFragmentState = FooterTabFragmentState & {
  filter: ContactsFilter,
}

enum ContactsFilter {
  CONNECTIONS = "connections",
  INVITATIONS = "invitations",
}

export class PeopleFragment extends FooterTabFragment<PeopleFragmentProps, PeopleFragmentState> {

  protected onCreateState(): PeopleFragmentState {
    return {
      ...super.onCreateState(),
      filter: ContactsFilter.CONNECTIONS,
    };
  }

  renderContent(): React.ReactElement | null {
    let rendered;
    switch (this.state.filter) {
      case ContactsFilter.CONNECTIONS:
        rendered = <ConnectionsFragment/>;
        break;
      case ContactsFilter.INVITATIONS:
        rendered = <InvitationsFragment/>;
        break;
    }
    return <StyledBoxColumnWithBackgroundCoverImage
      style={{flexGrow: 1}}
      backgroundCoverImage={"/images/cover.png"}>
      <Box style={{display: "flex", gap: PD_SM, margin: "auto", paddingTop: PD_LG}}>
        <Button
          color="secondary"
          variant={this.state.filter === ContactsFilter.CONNECTIONS ? "contained" : "outlined"}
          onClick={() => this.setState({filter: ContactsFilter.CONNECTIONS})}>
          <Box style={{display: "flex", gap: PD_XSM, alignItems: "center"}}>
            <PersonOutlined/>
            <Typography variant="h6">Connections</Typography>
          </Box>
        </Button>
        <Button
          color="secondary"
          variant={this.state.filter === ContactsFilter.INVITATIONS ? "contained" : "outlined"}
          onClick={() => this.setState({filter: ContactsFilter.INVITATIONS})}>
          <Box style={{display: "flex", gap: PD_XSM, alignItems: "center"}}>
            <MailOutlineOutlined/>
            <Typography variant="h6">Invitations</Typography>
          </Box>
        </Button>
      </Box>
      {rendered}
    </StyledBoxColumnWithBackgroundCoverImage>;
  }
}
