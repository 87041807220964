import {
  FullscreenDialogWithTitleFragment,
  FullscreenDialogWithTitleFragmentProps,
  FullscreenDialogWithTitleFragmentState
} from "../../shared/FullscreenDialogWithTitleFragment";
import {Box, Button, ButtonBase, ButtonProps, IconButton, Tooltip, Typography} from "@mui/material";
import {
  StyledAvatar,
  StyledBoxColumn,
  StyledBoxColumnWithBackgroundCoverImage,
  StyledBoxRow,
  StyledContainer,
  StyledSpan
} from "../../shared/StyledComponents";
import React, {createRef, ReactElement} from "react";
import {
  BORDER_RADIUS,
  DIVIDER,
  DIVIDER_COLOR,
  DW_XS,
  PD_LG,
  PD_MD,
  PD_SM,
  PD_XLG,
  PD_XSM,
  PD_XXLG,
  SZ_LG,
  SZ_MD,
  SZ_SM,
  SZ_SSM
} from "../../shared/dimens";
import {BaseApp, DIALOG_FLAG_ANIM_SLIDE, DIALOG_FLAG_SHOW_CLOSE} from "../../shared/BaseApp";
import {Action, ActionBase, ListItemChange, OnListItemsListener, UserDisplayName} from "../../shared/types";
import {
  AddOutlined,
  BackspaceOutlined,
  Circle,
  DeleteOutlined,
  EditOutlined,
  LineWeightOutlined,
  RedoOutlined,
  RestartAltOutlined,
  UndoOutlined
} from "@mui/icons-material";
import {App} from "../App";
import {DrawSegment, MAX_LETTERS, Room, RoomDrawing, RoomDrawings, Rooms, RoomWord, RoomWords} from "../types";
import {BaseFragment, BaseFragmentProps, BaseFragmentState} from "../../shared/BaseFragment";
import {getMemberAuth} from "../../shared/auth";
import {
  colorAlabaster,
  colorBlue,
  colorEarthBlue,
  colorEarthGreen,
  colorEarthLightBrown,
  colorEarthRed,
  colorRed,
  colorYellow,
  white
} from "../../shared/colors";
import {CustomDialogContent} from "../../shared/Dialogs";
import Confetti from "react-confetti";
import {Member} from "../../shared/entities";
import {NewPageHelper} from "./EditGameHelper";

function ViewRoomCodeView(props: { room: Room, onDismiss: () => void }): ReactElement {
  return <CustomDialogContent
    style={{minWidth: DW_XS, width: null}}
    title="Your room code"
    customView={
      <StyledBoxColumn>
        <Typography
          style={{fontSize: "400%", fontWeight: "bold", textAlign: "center"}}>
          {props.room.id}
        </Typography>
        {props.room.joinedBy
          ? <Typography style={{textAlign: "center"}}>Ask the other player to enter this code to join.</Typography>
          : null}
        <Button
          style={{
            fontSize: "150%",
            fontFamily: "Gabarito, sans-serif",
            paddingLeft: PD_XXLG,
            paddingRight: PD_XXLG
          }}
          variant="contained"
          onClick={() => props.onDismiss()}>
          Okay!
        </Button>
      </StyledBoxColumn>
    }/>;
}

function CompletedGameView(props: { room: Room }): ReactElement {
  return <CustomDialogContent
    style={{width: DW_XS}}
    title="Congratulations!"
    customView={
      <StyledBoxColumn style={{alignItems: "center", padding: PD_XLG, gap: PD_XLG}}>
        <img src={"/images/thumbs_up.png"} style={{width: 240}}/>
        <Typography
          style={{fontSize: "100%", fontWeight: "bold", textAlign: "center"}}>
          {props.room.isDrawer()
            ? <>{UserDisplayName(props.room.joiner.user)} correctly guessed your drawing</>
            : <>You correctly guessed the drawing by {UserDisplayName(props.room.member.user)}</>}
        </Typography>
      </StyledBoxColumn>
    }/>;
}

const CANVAS_MAX_SIZE = 480;

type BaseGameContentFragmentProps = FullscreenDialogWithTitleFragmentProps & {
  initialRoom: Room,
  onRoomDidReset: (room: Room) => void,
}

type BaseGameContentFragmentState = FullscreenDialogWithTitleFragmentState & {
  room: Room,
  segments: DrawSegment[],
  guessWord: string,
  showCompleted?: string,
}

abstract class BaseGameContentFragment<P extends BaseGameContentFragmentProps = BaseGameContentFragmentProps, S extends BaseGameContentFragmentState = BaseGameContentFragmentState> extends FullscreenDialogWithTitleFragment<P, S> implements OnListItemsListener<Room>, OnListItemsListener<RoomDrawing>, OnListItemsListener<RoomWord> {

  protected readonly canvasParentRef = createRef<HTMLDivElement>();
  protected readonly canvasRef = createRef<HTMLCanvasElement>();

  private readonly memberId = getMemberAuth().getMemberId();

  protected onCreateState(): S {
    return {
      ...super.onCreateState(),
      room: this.props.initialRoom,
    };
  }

  protected async fetchOnMount(forceReload?: boolean): Promise<void> {
    this.setState({
      segments: (await RoomDrawings.getInstance().getOrLoadItem(this.props.initialRoom.id))?.segments || [],
      guessWord: (await RoomWords.getInstance().getOrLoadItem(this.props.initialRoom.id))?.word || "",
    });
  }

  componentDidMount() {
    super.componentDidMount();
    RoomDrawings.getInstance().registerChildObserver(this.state.room.id, this);
    RoomWords.getInstance().registerChildObserver(this.state.room.id, this);
    Rooms.getInstance().registerChildObserver(this.state.room.id, this);
  }

  componentWillUnmount() {
    window.onresize = null;
    RoomDrawings.getInstance().unregisterChildObserver(this.state.room.id, this);
    RoomWords.getInstance().unregisterChildObserver(this.state.room.id, this);
    Rooms.getInstance().unregisterChildObserver(this.state.room.id, this);
  }

  onItemChanged(item: Room | RoomDrawing | RoomWord, change: ListItemChange) {
    if (item instanceof Room) {
      if (item.id !== this.state.room.id) {
        return;
      }
      this.setState({
        room: item,
      });
    } else if (item instanceof RoomDrawing) {
      this.setState({segments: item.segments});
    } else if (item instanceof RoomWord) {
      this.setState({guessWord: item.word});
    }
  }

  componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot?: any) {
    super.componentDidUpdate(prevProps, prevState, snapshot);
    if (prevState.room.word !== this.state.room.word) {
      this.onReset();
    } else {
      if (prevState.room.completedAt !== this.state.room.completedAt && this.state.room.completedAt) {
        this.setState({
          showCompleted: App.CONTEXT.showDialog(
            {flags: DIALOG_FLAG_ANIM_SLIDE},
            () => <CompletedGameView room={this.state.room}/>)
        });
        setTimeout(() => this.setState({showCompleted: null}), 5000);
      }
      if (Boolean(prevState.showCompleted) && !Boolean(this.state.showCompleted)) {
        this.hideDialog(prevState.showCompleted);
      }
    }
  }

  protected async onReset() {
    this.setState({
      segments: [],
      guessWord: "",
    });
    await RoomDrawings.getInstance().addListItem(RoomDrawing.createNew(this.state.room.id, []));
    await RoomWords.getInstance().addListItem(RoomWord.createNew(this.state.room.id, ""));
    this.props.onRoomDidReset(this.state.room);
  }

  protected containerContentDidRender() {
    const divElement = this.canvasParentRef.current;
    this.resizeCanvas(divElement.clientWidth, divElement.clientWidth);
    window.onresize = () => {
      this.resizeCanvas(divElement.clientWidth, divElement.clientWidth);
    };
  }

  getCustomTitle(): ReactElement {
    return <StyledBoxRow>
      <img src={"/images/logotype.png"} style={{height: 48}}/>
    </StyledBoxRow>;
  }

  renderContent() {
    return <Box style={{display: "flex", flexDirection: "column", flexGrow: 1}}>
      {this.renderGameToolbar()}
      <Box style={{display: "flex", flexDirection: "column", flexGrow: 1, position: "relative"}}>
        {this.renderDraw()}
        {Boolean(this.state.showCompleted)
          ? <Confetti
            style={{
              position: "absolute",
              left: 0,
              right: 0,
              top: 0,
              height: "100%",
              zIndex: 1000
            }}
            run/>
          : null}
        {this.state.guessWord === this.state.room.word
          ? <div style={{
            pointerEvents: "all",
            position: "absolute",
            left: 0,
            right: 0,
            top: 0,
            height: "100%",
            zIndex: 1100,
          }}/>
          : null}
      </Box>
    </Box>;
  }

  private renderDraw(): ReactElement {
    if (this.canvasRef.current) {
      this.renderDrawing(this.state.segments);
    }
    return <>
      <StyledBoxColumnWithBackgroundCoverImage
        backgroundCoverImage={"/images/cover.png"}
        style={{
          alignItems: "center",
          padding: PD_MD,
          flexGrow: 1,
          background: colorAlabaster,
        }}>
        <div style={{
          position: "relative",
          width: "100%",
          maxWidth: CANVAS_MAX_SIZE,
        }}>
          <div style={{
            position: "absolute",
            width: "100%",
            aspectRatio: 1,
            transform: "rotate(3deg) translateY(12px)",
            background: "white",
            border: DIVIDER_COLOR + " 1px solid",
            borderRadius: BORDER_RADIUS,
          }}/>
          <div style={{
            position: "absolute",
            width: "100%",
            aspectRatio: 1,
            transform: "rotate(1deg) translateY(12px)",
            background: "white",
            border: DIVIDER_COLOR + " 1px solid",
            borderRadius: BORDER_RADIUS,
          }}/>
          <div ref={this.canvasParentRef}
               style={{
                 zIndex: 10,
                 background: "white",
                 width: "100%",
                 border: DIVIDER_COLOR + " 1px solid",
                 borderRadius: BORDER_RADIUS,
                 position: "relative",
               }}>
            <canvas
              ref={this.canvasRef}
              onMouseDown={event => this.onCanvasMouseDown(event)}
              onTouchStart={event => this.onCanvasTouchStart(event)}
              onMouseMove={event => this.onCanvasMouseMove(event)}
              onTouchMove={event => this.onCanvasTouchMove(event)}
              onMouseUp={event => this.onCanvasMouseUp(event)}
              onTouchEnd={event => this.onCanvasTouchEnd(event)}
            />
            {this.renderCanvasOverlays()}
          </div>
        </div>
      </StyledBoxColumnWithBackgroundCoverImage>
      {this.renderToolStrip()}
      <Box style={{
        display: "flex",
        flexShrink: 0,
        height: SZ_LG,
        alignItems: "center",
        justifyContent: "center",
        paddingLeft: PD_MD,
        paddingRight: PD_MD,
        paddingBottom: BaseApp.CONTEXT.getAppConfig().safeAreaInsets?.bottom,
        gap: PD_MD,
        borderTop: DIVIDER,
        left: 0,
        right: 0,
        bottom: 0,
        background: BaseApp.CONTEXT.getAppConfig().theme?.palette.background.paper,
      }}>
        {this.renderFooter()}
      </Box>
    </>
  }

  protected renderCanvasOverlays(): ReactElement {
    return null;
  }

  protected onCanvasMouseDown(event: React.MouseEvent): void {
  }

  protected onCanvasTouchStart(event: React.TouchEvent): void {
  }

  protected onCanvasMouseMove(event: React.MouseEvent): void {
  }

  protected onCanvasTouchMove(event: React.TouchEvent): void {
  }

  protected onCanvasMouseUp(event: React.MouseEvent): void {
  }

  protected onCanvasTouchEnd(event: React.TouchEvent): void {
  }

  protected renderToolStrip(): ReactElement {
    return null;
  }

  protected abstract renderFooter(): ReactElement;

  private renderGameToolbar() {
    const otherMember = this.state.room.creator === getMemberAuth().getMemberId() ? this.state.room.joiner : this.state.room.member;
    return <StyledBoxRow
      style={{
        boxSizing: "border-box",
        height: SZ_MD,
        gap: 0,
      }}>
      <ButtonBase onClick={() => {
        App.CONTEXT.showDialog({flags: DIALOG_FLAG_SHOW_CLOSE | DIALOG_FLAG_ANIM_SLIDE}, () =>
          <ViewRoomCodeView
            room={this.state.room}
            onDismiss={() => {
              App.CONTEXT.hideDialog();
            }}/>
        );
      }}>
        <StyledBoxColumn style={{
          height: "100%",
          flexShrink: 0,
          width: SZ_LG,
          gap: PD_XSM,
          padding: PD_SM,
          background: colorBlue,
          color: white,
          alignItems: "center",
          justifyContent: "center"
        }}>
          <Typography variant="h6">{this.state.room.id}</Typography>
          {/*<Typography variant="h6">{"00:39"}</Typography>*/}
        </StyledBoxColumn>
      </ButtonBase>
      <StyledBoxRow style={{flexGrow: 1, alignItems: "center", paddingLeft: PD_SM, background: colorEarthLightBrown}}>
        <StyledAvatar member={otherMember}/>
        <StyledBoxColumn style={{gap: 0}}>
          <Typography style={{fontSize: "110%"}}>
            {this.state.room.isDrawer() ?
              <>Your word is <b>{this.state.room.word}</b>.</>
              : <>You are guessing.</>}
          </Typography>
          <Typography>
            {otherMember
              ? this.getOtherMemberText(otherMember)
              : "Waiting for a player to join..."}
          </Typography>
        </StyledBoxColumn>
        <StyledSpan/>
        {this.renderToolbarButtons()}
      </StyledBoxRow>
    </StyledBoxRow>
  }

  private getOtherMemberText(otherMember: Member): string {
    if (this.state.room.isDrawer() && this.state.guessWord?.length > 0) {
      if (this.state.guessWord.length === this.state.room.word.length) {
        return `${UserDisplayName(otherMember?.user)} guessed the word ${this.state.guessWord}.`
      }
      return `${UserDisplayName(otherMember?.user)} is guessing...`;
    }
    return `Playing with ${UserDisplayName(otherMember?.user)}`;
  }

  protected renderToolbarButtons() {
    return <>
      <Button
        style={{width: 72, height: 72, background: colorEarthRed}}
        variant="contained"
        onClick={() => new NewPageHelper(this.props.path, this.state.room).updateRoom()}>
        <AddOutlined style={{width: 40, height: 40}}/>
      </Button>
      {/*<IconButton onClick={(event) => BaseApp.CONTEXT.showActions(event.target as HTMLButtonElement, null, [*/}
      {/*  // new Action("Abandon game", () => this.onAbandonGame()),*/}
      {/*  new Action("Help", () => this.onHelp()),*/}
      {/*])}>*/}
      {/*  <MoreHorizOutlined/>*/}
      {/*</IconButton>*/}
    </>
  }

  private onHelp() {
    BaseApp.CONTEXT.showDialog({flags: DIALOG_FLAG_SHOW_CLOSE}, props => {
      return <StyledBoxColumn style={{padding: PD_LG}}>
        <Typography variant="h5">How to play Pikaso</Typography>
        <Typography>TO BE DONE!</Typography>
      </StyledBoxColumn>;
    });
  }

  private resizeCanvas(width: number, height: number) {
    const current = this.canvasRef.current;
    if (current.width === width && current.height === height) {
      return;
    }
    current.width = width;
    current.height = height;
    this.forceUpdate();
  }

  protected renderDrawing(segments: DrawSegment[]) {
    const canvas = this.canvasRef.current;
    const width = canvas.width;
    const height = canvas.height;
    const scale = width / CANVAS_MAX_SIZE;
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, width, height);

    ctx.lineCap = "round";
    ctx.lineJoin = "round";
    if (segments) {
      for (const segment of segments) {
        if (segment.points?.length > 0) {
          ctx.strokeStyle = segment.color;
          ctx.lineWidth = segment.lineWidth * scale;
          ctx.beginPath();
          ctx.moveTo(segment.points[0] * width, segment.points[1] * height);
          for (let i = 2; i < segment.points.length; i += 2) {
            ctx.lineTo(segment.points[i] * width, segment.points[i + 1] * height);
          }
          ctx.stroke();
        }
      }
    }
  }
}

export type GameWordFragmentProps = BaseGameContentFragmentProps & {}

function KeyboardButton(props: ButtonProps) {
  return <Button
    style={{minWidth: 0, width: 64, height: SZ_SSM}}
    variant="contained"
    {...props}>
    {props.children}
  </Button>;
}

type GameWordFragmentState = BaseGameContentFragmentState & {
  guessLetters: string[],
  guessIndex: number,
}

export class GameWordFragment extends BaseGameContentFragment<GameWordFragmentProps, GameWordFragmentState> {

  protected onCreateState(): GameWordFragmentState {
    return {
      ...super.onCreateState(),
      guessIndex: 0,
    };
  }

  protected async onReset() {
    await super.onReset();
    this.setState({
      guessLetters: new Array<string>(this.state.room.word.length).fill(""),
      guessIndex: 0,
    });
  }

  protected async fetchOnMount(forceReload?: boolean): Promise<void> {
    await super.fetchOnMount(forceReload);
    const guessWord = (await RoomWords.getInstance().getOrLoadItem(this.props.initialRoom.id))?.word || "";
    const guessLetters = new Array<string>(this.state.room.word.length).fill("");
    guessWord.split("").map((letter, index) => guessLetters[index] = letter);
    this.setState({
      guessLetters: guessLetters,
      guessIndex: guessWord.length,
    });
  }

  componentDidUpdate(prevProps: Readonly<GameWordFragmentProps>, prevState: Readonly<GameWordFragmentState>, snapshot?: any) {
    super.componentDidUpdate(prevProps, prevState, snapshot);
    if (prevState.guessLetters !== this.state.guessLetters) {
      const guessLetters = this.state.guessLetters;
      const guessIndex = this.state.guessIndex;
      RoomWords.getInstance().addListItem(RoomWord.createNew(this.state.room.id, this.state.guessLetters.join("")));
      if (!this.state.room.completedAt && guessIndex >= guessLetters.length) {
        const guessWord = guessLetters.join("");
        if (guessWord === this.state.room.word) {
          const room = this.state.room.clone<Room>(Room);
          room.completedAt = Date.now();
          Rooms.getInstance().addListItem(room);
        }
      }
    }
  }

  protected renderToolStrip(): React.ReactElement {
    return this.renderLetterSlots();
  }

  private renderLetterSlots() {
    let background = colorYellow;
    if (this.state.guessIndex >= this.state.guessLetters.length) {
      const guessWord = this.state.guessLetters.join("");
      if (guessWord === this.state.room.word) {
        background = "#0f0";
      } else {
        background = colorRed;
      }
    }
    return <StyledBoxRow
      className="hidescroll"
      style={{
        padding: PD_SM,
        boxSizing: "border-box",
        height: SZ_SM,
        gap: PD_XSM,
        background: background,
        alignItems: "center",
      }}>
      <StyledSpan/>
      {this.state.guessLetters.map(letter => this.renderLetterSlotButton(letter))}
      <StyledSpan/>
    </StyledBoxRow>
  }

  private renderLetterSlotButton(letter?: string): ReactElement {
    return <Button
      style={{minWidth: 0, width: 40, height: 40, border: "4px solid", background: "white"}}>
      <Typography variant="h6">
        {letter}
      </Typography>
    </Button>;
  }

  protected renderFooter(): React.ReactElement {
    return <StyledContainer size={"sm"}>
      <StyledBoxColumn style={{gap: PD_XSM}}>
        <StyledBoxRow style={{gap: PD_XSM}}>
          {this.state.room.letters.slice(0, MAX_LETTERS / 2).map(letter => this.renderLetterButton(letter))}
          <KeyboardButton
            style={{backgroundColor: colorEarthGreen}}
            onClick={() => this.setState({
              guessLetters: new Array<string>(this.state.guessLetters.length).fill(""),
              guessIndex: 0
            })}>
            <RestartAltOutlined/>
          </KeyboardButton>
        </StyledBoxRow>
        <StyledBoxRow style={{gap: PD_XSM}}>
          {this.state.room.letters.slice(MAX_LETTERS / 2, MAX_LETTERS).map(letter => this.renderLetterButton(letter))}
          <KeyboardButton
            style={{backgroundColor: colorEarthBlue}}
            onClick={() => {
              if (this.state.guessIndex <= 0) {
                return;
              }
              const guessLetters = [...this.state.guessLetters];
              guessLetters[this.state.guessIndex - 1] = "";
              this.setState({
                guessLetters: guessLetters,
                guessIndex: this.state.guessIndex - 1,
              });
            }}>
            <BackspaceOutlined/>
          </KeyboardButton>
        </StyledBoxRow>
      </StyledBoxColumn>
    </StyledContainer>;
  }

  private renderLetterButton(letter: string): ReactElement {
    return <KeyboardButton
      onClick={() => {
        if (this.state.guessIndex >= this.state.guessLetters.length) {
          return;
        }
        const guessLetters = [...this.state.guessLetters];
        guessLetters[this.state.guessIndex] = letter;
        const guessIndex = this.state.guessIndex + 1;
        this.setState({guessLetters: guessLetters, guessIndex: guessIndex});
      }}>
      <Typography variant="h6">
        {letter}
      </Typography>
    </KeyboardButton>;
  }
}

enum DrawTool {
  DRAW = "draw",
  ERASE = "erase",
}

const COLORS: string[] = [
  "#000",
  "#999",
  "#eee",
  "#ff0000",
  "#fc4444",
  "#fc6404",
  "#fcd444",
  "#8cc43c",
  "#029658",
  "#1abc9c",
  "#5bc0de",
  "#6454ac",
  "#fc8c84",
];

export type GameDrawFragmentProps = BaseGameContentFragmentProps & {}

type UndoRedoStackItem = {
  localSegments: DrawSegment[],
}

type GameDrawFragmentState = BaseGameContentFragmentState & {
  drawTool: DrawTool,
  drawColor: string,
  drawLineWidth: number,
  localSegments: DrawSegment[],
  dragPoints?: number[],
  undoStack: UndoRedoStackItem[],
  redoStack: UndoRedoStackItem[],
}

export class GameDrawFragment extends BaseGameContentFragment<GameDrawFragmentProps, GameDrawFragmentState> {

  protected onCreateState(): GameDrawFragmentState {
    return {
      ...super.onCreateState(),
      drawTool: DrawTool.DRAW,
      drawColor: COLORS[0],
      drawLineWidth: 8,
      undoStack: [],
      redoStack: [],
    };
  }

  protected async onReset() {
    await super.onReset();
    this.setState({
      localSegments: [],
      dragPoints: [],
      undoStack: [],
      redoStack: [],
    });
  }

  componentDidUpdate(prevProps: Readonly<GameDrawFragmentProps>, prevState: Readonly<GameDrawFragmentState>, snapshot?: any) {
    super.componentDidUpdate(prevProps, prevState, snapshot);
    if (prevState.localSegments !== this.state.localSegments) {
      RoomDrawings.getInstance().addListItem(RoomDrawing.createNew(this.state.room.id, this.state.localSegments));
    }
  }

  protected async fetchOnMount(forceReload?: boolean): Promise<void> {
    await super.fetchOnMount(forceReload);
    this.setState({
      localSegments: (await RoomDrawings.getInstance().getOrLoadItem(this.props.initialRoom.id))?.segments || [],
    });
  }

  protected renderToolStrip(): React.ReactElement {
    return this.state.drawTool === DrawTool.DRAW
      ? this.renderDrawToolColorStrip()
      : null;
  }

  protected renderFooter(): React.ReactElement {
    return <>
      {this.renderDrawToolButton(new Action("Draw", () => this.setState({drawTool: DrawTool.DRAW}), EditOutlined).setSelected(this.state.drawTool === DrawTool.DRAW))}
      {/*{this.renderDrawToolButton(new Action("Erase", () => this.setState({drawTool: DrawTool.ERASE}), findIcon("eraser")).setSelected(this.state.drawTool === DrawTool.ERASE))}*/}
      {this.renderDrawToolButton(new Action("Delete", () => {
        App.CONTEXT.showTextDialog("Confirm delete", "Are you sure you want to clear? This action cannot be undone.", new Action("Yes, I'm sure", () => this.setState({
          localSegments: [],
          undoStack: [],
          redoStack: []
        })), null, true, {width: 320});
      }, DeleteOutlined))}
      {/*{this.renderDrawToolButton(new Action("Done", () => {*/}
      {/*}, CheckOutlined).setVariant("contained"))}*/}
    </>;
  }

  protected renderCanvasOverlays(): React.ReactElement {
    return <StyledBoxRow
      style={{position: "absolute", left: 0, top: 0, right: 0, padding: PD_MD, alignItems: "center"}}>
      <Tooltip title={"Undo"}>
        <IconButton disabled={!(this.state.undoStack?.length > 0)} onClick={() => this.onUndo()}>
          <UndoOutlined/>
        </IconButton>
      </Tooltip>
      <StyledSpan/>
      <Tooltip title={"Redo"}>
        <IconButton disabled={!(this.state.redoStack?.length > 0)} onClick={() => this.onRedo()}>
          <RedoOutlined/>
        </IconButton>
      </Tooltip>
    </StyledBoxRow>
  }

  private onUndo() {
    if (!(this.state.undoStack?.length > 0)) {
      return;
    }
    const last = this.state.undoStack[this.state.undoStack.length - 1];
    this.setState({
      localSegments: last.localSegments,
      undoStack: this.state.undoStack.slice(0, this.state.undoStack.length - 1),
      redoStack: [...this.state.redoStack, {localSegments: this.state.localSegments}],
    });
  }

  private onRedo() {
    if (!(this.state.redoStack?.length > 0)) {
      return;
    }
    const last = this.state.redoStack[this.state.redoStack.length - 1];
    this.setState({
      localSegments: last.localSegments,
      redoStack: this.state.redoStack.slice(0, this.state.redoStack.length - 1),
      undoStack: [...this.state.undoStack, {localSegments: this.state.localSegments}],
    });
  }

  protected onCanvasMouseDown(event: React.MouseEvent) {
    this.onDown(event.clientX, event.clientY);
  }

  protected onCanvasTouchStart(event: React.TouchEvent) {
    this.onDown(event.touches[0].clientX, event.touches[0].clientY);
  }

  private onDown(clientX: number, clientY: number) {
    const rect = this.canvasRef.current.getBoundingClientRect();
    this.setState({dragPoints: [(clientX - rect.x) / rect.width, (clientY - rect.y) / rect.height]});
    this.forceUpdate();
  }

  protected onCanvasMouseMove(event: React.MouseEvent) {
    this.onMove(event.clientX, event.clientY);
  }

  protected onCanvasTouchMove(event: React.TouchEvent) {
    this.onMove(event.touches[0].clientX, event.touches[0].clientY);
  }

  private onMove(clientX: number, clientY: number) {
    if (!(this.state.dragPoints?.length > 0)) {
      return;
    }
    const rect = this.canvasRef.current.getBoundingClientRect();
    this.setState({dragPoints: [...this.state.dragPoints, (clientX - rect.x) / rect.width, (clientY - rect.y) / rect.height]});
    this.forceUpdate();
  }

  protected onCanvasMouseUp(event: React.MouseEvent) {
    this.onUp(event.clientX, event.clientY);
  }

  protected onCanvasTouchEnd(event: React.TouchEvent) {
    this.onUp(event.touches[0]?.clientX, event.touches[0]?.clientY);
  }

  private onUp(clientX: number, clientY: number) {
    this.setState({
      localSegments: [...this.state.localSegments, new DrawSegment(
        this.state.drawColor,
        this.state.drawLineWidth,
        this.state.dragPoints
      )],
      undoStack: [...this.state.undoStack, {
        localSegments: this.state.localSegments,
      }],
      redoStack: [],
      dragPoints: null,
    });
    this.forceUpdate();
  }

  private renderDrawToolButton(action: Action): ReactElement {
    const IconType = action.iconType;
    return <Button style={{width: 96, height: 72, border: (action.selected ? "3px solid" : null)}}
                   variant={action.variant}
                   onClick={action.onClick}>
      <StyledBoxColumn style={{gap: 4, justifyContent: "center", alignItems: "center"}}>
        <IconType style={{width: 32, height: 32}}/>
        <Typography style={{fontWeight: "bold", fontSize: "90%", textTransform: "uppercase"}}>{action.text}</Typography>
      </StyledBoxColumn>
    </Button>
  }

  private static readonly LINE_WIDTHS: number[] = [4, 8, 12, 16];
  private static readonly LINE_WIDTH_TEXTS: string[] = ["Thin", "Regular", "Thick", "Very thick"];

  private readonly drawLineWidthActions: ActionBase[] = GameDrawFragment.LINE_WIDTHS.map((width, index) =>
    new Action(GameDrawFragment.LINE_WIDTH_TEXTS[index], () => this.setState({drawLineWidth: width})));

  private renderDrawToolColorStrip() {
    let background = white;
    if (this.state.guessWord?.length >= this.state.room.word.length) {
      if (this.state.guessWord === this.state.room.word) {
        background = "#0f0";
      } else {
        background = colorRed;
      }
    }
    return <StyledBoxRow
      className="hidescroll"
      style={{
        padding: PD_SM,
        boxSizing: "border-box",
        height: SZ_SM,
        borderTop: DIVIDER,
        background: background,
        alignItems: "center",
        overflowX: "scroll"
      }}>
      <StyledSpan/>
      <Button
        style={{minWidth: 0, width: 48, flexShrink: 0}}
        onClick={event => App.CONTEXT.showActionsListPopover(event.target as HTMLElement, null, this.drawLineWidthActions)}>
        <LineWeightOutlined style={{width: 24, height: 24}}/>
      </Button>
      {COLORS.map(color => this.renderDrawToolColorButton(color, color === this.state.drawColor))}
      <StyledSpan/>
    </StyledBoxRow>
  }

  private renderDrawToolColorButton(color: string, selected?: boolean): ReactElement {
    return <Button
      style={{minWidth: 0, width: 48, flexShrink: 0, border: (selected ? "3px solid" : null)}}
      onClick={() => this.setState({drawColor: color})}>
      <Circle style={{width: 24, height: 24, color: color}}/>
    </Button>;
  }

  protected renderDrawing(segments: DrawSegment[]) {
    super.renderDrawing(this.state.localSegments);
    const canvas = this.canvasRef.current;
    const width = canvas.width;
    const height = canvas.height;
    const scale = width / CANVAS_MAX_SIZE;
    const ctx = canvas.getContext("2d");

    if (this.state.dragPoints?.length > 0) {
      ctx.strokeStyle = this.state.drawColor;
      ctx.lineWidth = this.state.drawLineWidth * scale;
      ctx.beginPath();
      ctx.moveTo(this.state.dragPoints[0] * width, this.state.dragPoints[1] * height);
      for (let i = 2; i < this.state.dragPoints.length; i += 2) {
        ctx.lineTo(this.state.dragPoints[i] * width, this.state.dragPoints[i + 1] * height);
      }
      ctx.stroke();
    }
  }
}

export type GameFragmentProps = BaseFragmentProps & {
  initialRoom: Room,
}

type GameFragmentState = BaseFragmentState & {
  room: Room,
}

export class GameFragment extends BaseFragment<GameFragmentProps, GameFragmentState> {

  protected onCreateState(): GameFragmentState {
    return {
      ...super.onCreateState(),
      room: this.props.initialRoom,
    };
  }

  protected renderContainerContent(): React.ReactElement | null {
    return this.state.room.isDrawer()
      ? <GameDrawFragment
        path={this.props.path}
        initialRoom={this.state.room}
        onRoomDidReset={(room: Room) => this.setState({room: room})}/>
      : <GameWordFragment
        path={this.props.path}
        initialRoom={this.state.room}
        onRoomDidReset={(room: Room) => this.setState({room: room})}/>;
  }
}